import $ from "jquery";
//TOPへ戻る
const topBtn = document.getElementById("toTop");
topBtn === null || topBtn === void 0 ? void 0 : topBtn.addEventListener("click", function (e) {
    e.preventDefault();
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
});
//画面の一番下にきたらTOPへ戻るボタンに.is-hiddenを追加
window.addEventListener("scroll", function () {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
        topBtn === null || topBtn === void 0 ? void 0 : topBtn.classList.add("is-hidden");
    }
    else {
        topBtn === null || topBtn === void 0 ? void 0 : topBtn.classList.remove("is-hidden");
    }
});
class Menu {
    constructor() {
        var _a;
        this.menu_btn = document.getElementById("menu-btn");
        this.menu = document.querySelector("#menu");
        this.fadeOutTimeout = null;
        this.body = document.querySelector("body");
        (_a = this.menu_btn) === null || _a === void 0 ? void 0 : _a.addEventListener("click", this.toggleMenu.bind(this));
    }
    toggleMenu(e) {
        e.preventDefault();
        if (this.menu.style.display === "block") {
            console.log("fadeout");
            this.fadeOut();
        }
        else {
            console.log("fadein");
            this.fadeIn();
        }
    }
    fadeIn() {
        if (this.fadeOutTimeout) {
            clearTimeout(this.fadeOutTimeout);
            this.fadeOutTimeout = null;
        }
        this.menu.style.display = "block";
        this.menu_btn.classList.add("is-open");
        this.fadeOutTimeout = window.setTimeout(() => {
            this.menu.classList.add("fade-in");
            this.body.classList.add("open-menu");
        }, 10);
    }
    fadeOut() {
        this.menu.classList.remove("fade-in");
        this.menu_btn.classList.remove("is-open");
        this.body.classList.remove("open-menu");
        this.fadeOutTimeout = window.setTimeout(() => {
            this.menu.style.display = "none";
        }, 500);
    }
}
new Menu();
$(function () {
    $(".slide-toggle").on("click", function () {
        $(this).find(".slide-content").stop().slideToggle(300);
    });
});
