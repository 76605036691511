//import "/src/stylus/style.styl";
//import "/src/stylus/admin.styl";
//import "./lib/top";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GUI } from "lil-gui";
//const canvas = document.getElementById("webgl") ;
const path = "site_data" in window
    ? site_data.path + "/dist/assets/gltf/"
    : "/assets/gltf/";
class Three {
    constructor() {
        this.gui = null;
        //private gui  = null
        this.controllers = null;
        this.canvas = document.getElementById("webgl");
        this.parcent = 0;
        this.parcentAll = 0;
        this.model_blue = new THREE.Object3D();
        this.model_pink = new THREE.Object3D();
        this.animationScripts = [];
        this.animationScriptsAll = [];
        this.blue_option = {
            scale: 1,
            position: [1.28, 1.1, 2],
            rotation: [-0.4, 0.32, 0.01],
        };
        this.blue_option2 = {
            scale: 1,
            position: [2.96, -0.64, 4.9],
            rotation: [0.43, 0.4, 0.42],
        };
        this.pink_option = {
            scale: 1,
            position: [1.5, 2.1, -3.9],
            rotation: [0, 0.1, 0.1],
        };
        this.pink_option2 = {
            scale: 3.2,
            position: [5.4, 4.2, -7.6],
            rotation: [0.05, 0, -0.14],
        };
        /* SP */
        this.blue_option_sp = {
            scale: 1,
            position: [1.4, 0.5, 3.58],
            rotation: [-0.23, 0.44, 0.03],
        };
        /* public blue_option_sp = {
          scale: 0.8,
          position: [1.34, -0.06, 4.94],
          rotation: [0.68, 0.74, 0.45],
        }; */
        this.blue_option2_sp = {
            scale: 0.8,
            position: [1.34, -0.06, 4.94],
            rotation: [0.68, 0.74, 0.45],
        };
        this.pink_option_sp = {
            scale: 0.7,
            position: [1.32, 1.68, 0.52],
            rotation: [0.062, 0.056, 0.05],
        };
        /* public pink_option_sp = {
          scale: 2.8,
          position: [-0.5, 3.94, -6.8],
          rotation: [0, 0.37, -0.06],
        }; */
        this.pink_option2_sp = {
            scale: 2.8,
            position: [-0.5, 3.94, -6.8],
            rotation: [0, 0.37, -0.06],
        };
        this.isSP = window.innerWidth <= 768;
        this.canvas = document.getElementById("webgl");
        this.scene = new THREE.Scene();
        this.camera = this.setupCamera();
        this.renderer = this.setupRenderer();
        this.controls = this.setupControls();
        //this.setupGUI();
        this.setupLights();
        // 座標軸を表示
        //this.setupAxes();
        this.loadModels();
        window.addEventListener("resize", this.onWindowResize.bind(this));
        //スクロールイベント
        window.addEventListener("scroll", this.onScroll.bind(this));
        this.animate();
        /* this.addAnimationScript(0, 1, () => {
          console.log("test");
          console.log(this.parcent);
        }); */
    }
    setupCamera() {
        const aspect = this.isSP
            ? window.innerWidth / (window.innerWidth * 2)
            : window.innerWidth / window.innerHeight;
        const camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 20000);
        camera.position.set(0, 0, 10);
        camera.lookAt(this.scene.position);
        return camera;
    }
    setupRenderer() {
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setClearColor(0xffffff, 0);
        //renderer.setClearColor("red", 1);
        //画面のサイズに合わせる
        //renderer.setSize(window.innerWidth, window.innerHeight);
        //const size = window.innerWidth > 1180 ? 1180 : window.innerWidth;
        const size = window.innerWidth;
        if (this.isSP) {
            renderer.setSize(window.innerWidth, window.innerWidth * 2);
        }
        else {
            renderer.setSize(window.innerWidth, window.innerHeight);
        }
        //renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this.canvas.appendChild(renderer.domElement);
        return renderer;
    }
    setupControls() {
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        //スクロールを無効
        controls.enableZoom = false;
        controls.update();
        return controls;
    }
    setupLights() {
        const ambientLight = new THREE.AmbientLight(0xffffff, 2.0);
        this.scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        //this.scene.add(directionalLight);
        const spotLight = new THREE.SpotLight(0xffffff, 100.7);
        //spotLight.position.set(0, 0, 0);
        //this.scene.add(spotLight);
    }
    setupAxes() {
        const axes = new THREE.AxesHelper(20);
        this.scene.add(axes);
    }
    setupGUI() {
        this.gui = new GUI();
        this.gui.domElement.style.position = "fixid";
        this.gui.domElement.style.bottom = "0px";
        this.gui.domElement.style.left = "0px";
        this.gui.domElement.style.top = "auto";
    }
    loadModels() {
        const loaderGlb = new GLTFLoader().setPath(path);
        loaderGlb.load("G-one_ring_blue04.gltf", (gltf) => {
            //const size = 1.15;
            const model = gltf.scene;
            const blue_option = this.isSP ? this.blue_option_sp : this.blue_option;
            const scale = blue_option.scale;
            model.scale.set(scale, scale, scale);
            model.position.set(blue_option.position[0], blue_option.position[1], blue_option.position[2]);
            model.rotation.set(blue_option.rotation[0], blue_option.rotation[1], blue_option.rotation[2]);
            this.scene.add(model);
            this.model_blue = model;
            if (this.gui !== null) {
                this.gui.add(model.scale, "x", 0, 10).name("青: scale x");
                this.gui.add(model.scale, "y", 0, 10).name("青: scale y");
                this.gui.add(model.scale, "z", 0, 10).name("青: scale z");
                this.gui.add(model.position, "x", -10, 10).name("青: position x");
                this.gui.add(model.position, "y", -10, 10).name("青: position y");
                this.gui.add(model.position, "z", -10, 10).name("青: position z");
                this.gui
                    .add(model.rotation, "x", -Math.PI, Math.PI)
                    .name("青: rotation x");
                this.gui
                    .add(model.rotation, "y", -Math.PI, Math.PI)
                    .name("青: rotation y");
                this.gui
                    .add(model.rotation, "z", -Math.PI, Math.PI)
                    .name("青: rotation z");
            }
        }, (progress) => {
            //console.log((progress.loaded / progress.total) * 100 + "% loaded");
        }, (error) => {
            console.error("An error happened:", error);
        });
        loaderGlb.load("G-one_ring_pink04-1.gltf", (gltf) => {
            const model = gltf.scene;
            const pink_option = this.isSP ? this.pink_option_sp : this.pink_option;
            const scale = pink_option.scale;
            model.scale.set(scale, scale, scale);
            // モデルのバウンディングボックスを計算
            const box = new THREE.Box3().setFromObject(model);
            // モデルのサイズと中心を取得
            const size = box.getSize(new THREE.Vector3());
            const center = box.getCenter(new THREE.Vector3());
            // モデルの位置を原点に移動
            //model.position.sub(center);
            // モデルをスケーリング
            //model.scale.set(1, 1, 1);
            model.position.set(pink_option.position[0], pink_option.position[1], pink_option.position[2]);
            model.rotation.set(pink_option.rotation[0], pink_option.rotation[1], pink_option.rotation[2]);
            // シーンにモデルを追加
            this.scene.add(model);
            this.model_pink = model;
            if (this.gui !== null) {
                this.gui.add(model.scale, "x", 0, 10).name("ピンク: scale x");
                this.gui.add(model.scale, "y", 0, 10).name("ピンク: scale y");
                this.gui.add(model.scale, "z", 0, 10).name("ピンク: scale z");
                this.gui
                    .add(gltf.scene.position, "x", -10, 10)
                    .name("ピンク: position x");
                this.gui
                    .add(gltf.scene.position, "y", -10, 10)
                    .name("ピンク: position y");
                this.gui
                    .add(gltf.scene.position, "z", -10, 10)
                    .name("ピンク: position z");
                this.gui
                    .add(gltf.scene.rotation, "x", -Math.PI, Math.PI)
                    .name("ピンク: rotation x");
                this.gui
                    .add(gltf.scene.rotation, "y", -Math.PI, Math.PI)
                    .name("ピンク: rotation y");
                this.gui
                    .add(gltf.scene.rotation, "z", -Math.PI, Math.PI)
                    .name("ピンク: rotation z");
            }
        }, (progress) => { }, (error) => {
            console.error("An error happened:", error);
        });
    }
    loadModel(loader, modelPath, size) {
        loader.load(modelPath, (gltf) => {
            gltf.scene.position.set(0, 0, 0);
            gltf.scene.scale.set(size, size, size);
            this.scene.add(gltf.scene);
            this.camera.lookAt(0, 0, 0);
            //console.log(gltf.scene);
        }, (progress) => { }, (error) => {
            console.error("An error happened:", error);
        });
    }
    onWindowResize() {
        //const size = window.innerWidth > 1180 ? 1180 : window.innerWidth;
        this.isSP = window.innerWidth <= 768;
        if (this.isSP) {
            this.renderer.setSize(window.innerWidth, window.innerWidth * 2);
            this.camera.aspect = window.innerWidth / (window.innerWidth * 2);
        }
        else {
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.camera.aspect = window.innerWidth / window.innerHeight;
        }
        this.camera.updateProjectionMatrix();
    }
    animate() {
        requestAnimationFrame(this.animate.bind(this));
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
    }
    onScroll() {
        const target = document.querySelector("#target");
        if (!target)
            return;
        const targetRect = target.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        // ターゲット要素の下端の位置（ページ上端からの距離）
        const targetTop = targetRect.top;
        const targetBottom = targetRect.bottom + window.scrollY;
        // スクロール可能な最大距離
        const maxScroll = targetBottom - windowHeight;
        // 現在のスクロール位置
        const currentScroll = window.scrollY;
        // 進捗率の計算（0から1の範囲）
        const progress = Math.min(currentScroll / maxScroll, 1);
        this.parcent = progress;
        // 0から1の範囲に制限
        this.playScrollAnimation();
        //console.log("progress2", progress2);
        const scrollTop = window.scrollY || document.documentElement.scrollTop; // 現在のスクロール位置
        const documentHeight = document.documentElement.scrollHeight; // ドキュメント全体の高さ
        // #target要素が画面の一番下に来るまでのスクロール量を計算
        const targetBottom2 = targetRect.bottom + scrollTop;
        // 現在のスクロール位置とwindowHeightを合わせた位置を計算
        const scrollPosition = scrollTop + windowHeight;
        // ページ全体の一番下のスクロール位置を 1 とした割合
        const scrollPercentage = (scrollPosition - targetBottom2) / (documentHeight - targetBottom2);
        // 0 から 1 の範囲にクランプする
        const clampedPercentage = Math.min(Math.max(scrollPercentage, 0), 1);
        this.parcentAll = clampedPercentage;
        return progress;
    }
    lerp(start, end, alpha) {
        return start + (end - start) * alpha;
    }
    scaleParcent(start, end) {
        //let percentage = 0.2;
        let percentage = this.parcent;
        start = Math.max(0, Math.min(1, start));
        end = Math.max(0, Math.min(1, end));
        percentage = Math.max(0, Math.min(1, percentage));
        //console.log("percentage", percentage);
        if (start === end) {
            return start;
        }
        let normalizedValue = (percentage - start) / (end - start);
        return Math.max(0, Math.min(1, normalizedValue));
    }
    scaleParcentAll(start, end) {
        //let percentage = 0.2;
        let percentage = this.parcentAll;
        start = Math.max(0, Math.min(1, start));
        end = Math.max(0, Math.min(1, end));
        percentage = Math.max(0, Math.min(1, percentage));
        //console.log("percentage", percentage);
        if (start === end) {
            return start;
        }
        let normalizedValue = (percentage - start) / (end - start);
        return Math.max(0, Math.min(1, normalizedValue));
    }
    addAnimationScript(start, end, animationFunction) {
        this.animationScripts.push({ start, end, function: animationFunction });
    }
    addAnimationScriptAll(start, end, animationFunction) {
        this.animationScriptsAll.push({ start, end, function: animationFunction });
    }
    playScrollAnimation() {
        this.animationScripts.forEach((animation) => {
            if (this.parcent >= animation.start && this.parcent <= animation.end) {
                animation.function();
            }
        });
        this.animationScriptsAll.forEach((animation) => {
            if (this.parcentAll >= animation.start &&
                this.parcentAll <= animation.end) {
                animation.function();
            }
        });
    }
}
const canvas = document.getElementById("webgl");
if (canvas !== null) {
    const animation = new Three();
    animation.addAnimationScriptAll(0.2, 1, () => {
        const webgl = document.getElementById("webgl");
        const per = animation.scaleParcentAll(0.2, 1);
        /* console.log(
          "per all:",
          animation.lerp(0, 1, animation.scaleParcentAll(0.2, 1))
        ); */
        if (webgl) {
            webgl.style.transform = `translateY(${animation.lerp(0, 1, per) * 0.1 * 100}%) `;
        }
        // カメラのx座標を変更
        //animation.camera.position.x = animation.lerp(0, 2, per);
        // カメラのy座標を変更
        animation.camera.position.y = animation.lerp(0, 1, per);
    });
    animation.addAnimationScript(0, 1, () => {
        const top_tit1 = document.getElementById("top-tit1");
        const top_tit2 = document.getElementById("top-tit2");
        const top_tit3 = document.getElementById("top-tit3");
        const top_tit4 = document.getElementById("top-tit4");
        const top_tit5 = document.getElementById("top-tit5");
        /* const top_desc = document.getElementById("top-desc");
    
        if (top_desc) {
          const per = animation.scaleParcent(0, 1);
          //perが.6以上の場合is-activeを追加、それ以外は削除
          if (per > 0.6) {
            top_desc.classList.add("is-active");
          } else {
            top_desc.classList.remove("is-active");
          }
        } */
        if (top_tit1) {
            const per = animation.scaleParcent(0, 1);
            top_tit1.style.transform = `translateX(${animation.lerp(0, 0.9, per) * -100}%) scale(${animation.lerp(1, 0.5, per)}) skew(${animation.lerp(0, 20, per)}deg, 0deg)`;
            top_tit1.style.opacity = String(animation.lerp(1, 0, per));
            top_tit1.style.filter = `blur(${animation.lerp(0, 4, per)}px)`;
        }
        if (top_tit2) {
            const per = animation.scaleParcent(0.2, 1);
            top_tit2.style.transform = `translateX(${animation.lerp(0, 0.9, per) * -100}%)  scale(${animation.lerp(1, 0.5, per)}) skew(${animation.lerp(0, 20, per)}deg, 0deg)`;
            top_tit2.style.opacity = String(animation.lerp(1, 0, per));
            top_tit2.style.filter = `blur(${animation.lerp(0, 4, per)}px)`;
        }
        if (top_tit3) {
            const per = animation.scaleParcent(0.3, 1);
            top_tit3.style.transform = `translateX(${animation.lerp(0, 0.9, per) * -100}%)  scale(${animation.lerp(1, 0.5, per)}) skew(${animation.lerp(0, 20, per)}deg, 0deg)`;
            top_tit3.style.opacity = String(animation.lerp(1, 0, per));
            top_tit3.style.filter = `blur(${animation.lerp(0, 4, per)}px)`;
        }
        if (top_tit4) {
            const per = animation.scaleParcent(0.4, 1);
            top_tit4.style.transform = `translateX(${animation.lerp(0, 0.9, per) * -100}%)  scale(${animation.lerp(1, 0.5, per)}) skew(${animation.lerp(0, 20, per)}deg, 0deg)`;
            top_tit4.style.opacity = String(animation.lerp(1, 0, per));
            top_tit4.style.filter = `blur(${animation.lerp(0, 4, per)}px)`;
        }
        if (top_tit5) {
            const per = animation.scaleParcent(0.5, 1);
            top_tit5.style.transform = `translateX(${animation.lerp(0, 0.9, per) * -100}%)  scale(${animation.lerp(1, 0.5, per)}) skew(${animation.lerp(0, 20, per)}deg, 0deg)`;
            top_tit5.style.opacity = String(animation.lerp(1, 0, per));
            top_tit5.style.filter = `blur(${animation.lerp(0, 4, per)}px)`;
        }
    });
    animation.addAnimationScript(0, 1, () => {
        const blue_start = animation.isSP
            ? Object.assign({}, animation.blue_option_sp) : Object.assign({}, animation.blue_option);
        const blue_end = animation.isSP
            ? Object.assign({}, animation.blue_option2_sp) : Object.assign({}, animation.blue_option2);
        const size_blue = animation.lerp(blue_start.scale, blue_end.scale, animation.scaleParcent(0, 1));
        animation.model_blue.scale.set(size_blue, size_blue, size_blue);
        animation.model_blue.position.x = animation.lerp(blue_start.position[0], blue_end.position[0], animation.scaleParcent(0, 1));
        animation.model_blue.position.y = animation.lerp(blue_start.position[1], blue_end.position[1], animation.scaleParcent(0, 1));
        animation.model_blue.position.z = animation.lerp(blue_start.position[2], blue_end.position[2], animation.scaleParcent(0, 1));
        animation.model_blue.rotation.x = animation.lerp(blue_start.rotation[0], blue_end.rotation[0], animation.scaleParcent(0, 1));
        animation.model_blue.rotation.y = animation.lerp(blue_start.rotation[1], blue_end.rotation[1], animation.scaleParcent(0, 1));
        animation.model_blue.rotation.z = animation.lerp(blue_start.rotation[2], blue_end.rotation[2], animation.scaleParcent(0, 1));
        //guiで変更した値を反映
        //const size_pink = animation.lerp(1, 2, animation.scaleParcent(0, 1));
        //animation.model_pink.scale.set(size_pink, size_pink, size_pink);
        //animation.model_pinkをx,y,z回転させる
        const pink_start = animation.isSP
            ? Object.assign({}, animation.pink_option_sp) : Object.assign({}, animation.pink_option);
        const pink_end = animation.isSP
            ? Object.assign({}, animation.pink_option2_sp) : Object.assign({}, animation.pink_option2);
        const size_pink = animation.lerp(pink_start.scale, pink_end.scale, animation.scaleParcent(0, 1));
        animation.model_pink.scale.set(size_pink, size_pink, size_pink);
        animation.model_pink.position.x = animation.lerp(pink_start.position[0], pink_end.position[0], animation.scaleParcent(0, 1));
        animation.model_pink.position.y = animation.lerp(pink_start.position[1], pink_end.position[1], animation.scaleParcent(0, 1));
        animation.model_pink.position.z = animation.lerp(pink_start.position[2], pink_end.position[2], animation.scaleParcent(0, 1));
        animation.model_pink.rotation.x = animation.lerp(pink_start.rotation[0], pink_end.rotation[0], animation.scaleParcent(0, 1));
        animation.model_pink.rotation.y = animation.lerp(pink_start.rotation[1], pink_end.rotation[1], animation.scaleParcent(0, 1));
        animation.model_pink.rotation.z = animation.lerp(pink_start.rotation[2], pink_end.rotation[2], animation.scaleParcent(0, 1));
    });
    /* animation.addAnimationScript(0.6, 1, () => {
      //console.log(".5");
    }); */
}
