//import "/src/stylus/style.styl";
//import "/src/stylus/admin.styl";
//import "./lib/top";
import { initializeObserver } from "./lib/inviewObserver";
import "./lib/three";
import "./lib/common";
class App {
    constructor() {
        initializeObserver();
    }
}
new App();
