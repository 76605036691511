const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("viewed");
            observer.unobserve(entry.target);
        }
    });
};
export const initializeObserver = () => {
    const targetElements = document.querySelectorAll(".inview");
    const observer = new IntersectionObserver(handleIntersection);
    targetElements.forEach((element) => {
        observer.observe(element);
    });
};
